import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Component/pages/Auth/Login";
import Table from "./Component/pages/Payments/Table";
import PrivateRoute from "./Component/PrivateRoute/PrivateRoute";
import axios from "axios";
import UserContext from "./context/UserContext";
import useTokenRefresh from "./hooks/useTokenRefresh";
import useIdleTimeout from "./hooks/useIdleTimeout";
// Import your PrivateRoute component

function App() {
  useTokenRefresh();

  const isIdle = useIdleTimeout();

  useEffect(() => {
    if (isIdle) {
      // Perform logout
      const logout = async () => {
        try {
          await fetch(`${
            process.env.REACT_APP_API_URL ||
            "https://stagingpayment.chipchip.social/api/v1"
          }`+ "/logout", {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user-info")?.access_token}`,
            },
          });
        } catch (error) {
          console.error("Logout error:", error);
        } finally {
          // Clear user-info from localStorage
          localStorage.removeItem("user-info");
          // Redirect to login page
          window.location.href = "/login";
        }
      };

      logout();
    }
  }, [isIdle]);

  return (
    <Router>
      <Routes>
        {/* Redirect from root to /payments */}
        <Route path="/" element={<Navigate to="/payments" replace />} />

        <Route path="/login" element={<Login />} />

        {/* Private route for /table */}
        <Route element={<PrivateRoute />}>
          <Route path="/payments" element={<Table />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
