import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = () => {
  const userInfo = localStorage.getItem("user-info");

  if (userInfo) {
    try {
      const parsedUserInfo = JSON.parse(userInfo);
      const accessToken = parsedUserInfo.data.access_token;

      if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000; // Convert to seconds

        // Check if the token is expired
        if (decodedToken.exp > currentTime) {
          return <Outlet />; // Token is valid, render the protected route
        }

        if (decodedToken.iss !== "ccpayments.chipchip.social") {
          return <Outlet />;
        }
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  return <Navigate to="/login" replace />;
};

export default PrivateRoute;
