export function formatCreatedAt(createdAt) {
  const date = new Date(createdAt);

  // Format the date components
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  // Format the time components
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}-${month}-${year} ${hours}:${minutes}${ampm}`;
}

/**
 * 
 *   async function loginUser(cred) {
    try {
      const response = await fetch(
        (process.env.REACT_APP_API_URL ||
          "https://stagingpayment.chipchip.social/api/v1") +
          "/system/users/passkey-challenge",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Cookie: "user info",
          },
          credentials: "same-origin",

          body: JSON.stringify(
            // {
            //   country: "ETH",
            //   phone: cred.phone,
            //   password: cred.password,
            // },

            {
              email: "",
              phone: cred.phone,
            }
          ),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        setError(error.error.message);
        // throw new Error(error.message || "Login failed");
      }

      setSwitchPassword(true);
      const data = await response.json();
      setChallengeData(data);
      console.log("data", data);
    } catch (error) {
      console.error("Error logging in:", error);

      // throw error;
    }
  }

  async function secondLogin(cred) {
    console.log("challengeData", challengeData);
    try {
      let encryptedPassword = await encryptAES128(
        cred.password,
        challengeData.data.challenge
      );
      let payload = {
        phone: cred.phone,
        password: encryptedPassword,
        challenge_id: challengeData.data.id,
      };

      const response2 = await fetch(
        (process.env.REACT_APP_API_URL ||
          "https://stagingpayment.chipchip.social/api/v1") +
          "/system/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // console.log("response2", response2.data);
      try {
        if (response2.ok) {
          const data = await response2.json();
          saveUser(data);
          localStorage.setItem("user-info", JSON.stringify(data));
          navigate("/payments");
        }
      } catch (error) {
        console.log(error);
      }

      // return redirect("/table")
      // return data.user;
      // window.location.href = "/";
    } catch (error) {}
  }
  

 */
