import React, { createContext, useState, useEffect } from "react";

// Create the context
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Load user data from localStorage when the app loads
  useEffect(() => {
    const storedUser = localStorage.getItem("user-info");
    if (storedUser) {
      setUser(JSON.parse(storedUser).data);
    }
  }, []);

  useEffect(() => {
    console.log(user);
  }, [user]);

  // Function to save user data after login
  const saveUser = (data) => {
    localStorage.setItem("user-info", JSON.stringify(data));
    setUser(data.data);
  };

  // Update localStorage whenever the user data changes
  const updateUser = (newUserData) => {
    setUser(newUserData);
    localStorage.setItem("user-info", JSON.stringify({ data: newUserData }));
  };

  // Clear user data
  const clearUser = () => {
    setUser(null);
    localStorage.removeItem("user-info");
  };

  return (
    <UserContext.Provider value={{ user, updateUser, clearUser, saveUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Export the context
export default UserContext;
