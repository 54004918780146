import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import myImage from "../../../assets/icons/ChipChip.png";
import CustomPhoneInput from "../../CustomInputs/input/phone-input";
import { TextInput } from "../../CustomInputs/input/text-input";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import UserContext from "../../../context/UserContext";
import axios from "axios";

function Login() {
  const [password, setPassword] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [switchPassword, setSwitchPassword] = useState(false);
  const [challengeData, setChallengeData] = useState(null);
  const [inputType, setinputType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const userInfoCookie = "user info";

  const navigate = useNavigate();
  const { user, updateUser, saveUser } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onShowPasswordChange = () => {
    inputType === "password" ? setinputType("text") : setinputType("password");
    setShowPassword((sh) => !sh);
  };

  async function encryptAES128(plaintext, base64Key) {
    // console.log("base64Key", base64Key);
    // Decode the base64 key
    try {
      const keyBytes = Uint8Array.from(atob(base64Key), (c) => c.charCodeAt(0));

      if (keyBytes.length !== 16) {
        throw new Error("AES-128 key must be 16 bytes");
      }

      // Generate a random 12-byte nonce (GCM standard)
      const nonce = crypto.getRandomValues(new Uint8Array(12));

      // Import the key
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        keyBytes,
        { name: "AES-GCM" },
        false,
        ["encrypt"]
      );

      // Encrypt the plaintext
      const encoder = new TextEncoder();
      const ciphertext = await crypto.subtle.encrypt(
        {
          name: "AES-GCM",
          iv: nonce, // Initialization Vector (nonce)
        },
        cryptoKey,
        encoder.encode(plaintext)
      );

      // Concatenate nonce and ciphertext
      const combined = new Uint8Array(nonce.length + ciphertext.byteLength);
      combined.set(nonce);
      combined.set(new Uint8Array(ciphertext), nonce.length);

      // Encode the result to base64

      // console.log("heloop", btoa(String.fromCharCode(...combined)));

      return btoa(String.fromCharCode(...combined));
    } catch (error) {
      // console.log("error", error);
    }
  }

  async function loginUser(cred) {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ||
          "https://stagingpayment.chipchip.social/api/v1") +
          "/system/users/passkey-challenge",
        {
          email: "",
          phone: cred.phone,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Cookie: "user info",
          },
          // withCredentials: true, // equivalent to 'credentials: "same-origin"'
        }
      );

      setSwitchPassword(true);
      setChallengeData(response.data);
      // console.log("data", response.data);
    } catch (error) {
      console.error("Error logging in:", error);
      if (error.response) {
        setError(error.response.data.error.message);
      }
    }
  }

  async function secondLogin(cred) {
    // console.log("challengeData", challengeData);
    try {
      const encryptedPassword = await encryptAES128(
        cred.password,
        challengeData.data.challenge
      );

      const payload = {
        phone: cred.phone,
        password: encryptedPassword,
        challenge_id: challengeData.data.id,
      };

      const response = await axios.post(
        (process.env.REACT_APP_API_URL ||
          "https://stagingpayment.chipchip.social/api/v1") +
          "/system/users/login",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      saveUser(response.data);
      localStorage.setItem("user-info", JSON.stringify(response.data));
      navigate("/payments");
    } catch (error) {
      console.error("Error during second login:", error);
      if (error.response) {
        setError(error.response.data.error.message);
      }
    }
  }

  const onSubmit = async (e) => {
    setLoading(true);
    try {
      // console.log("Phone-Pass", { password, phone });
      if (switchPassword) {
        const response = await secondLogin({ password, phone });
      } else {
        const response = await loginUser({ password, phone });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Login failed:", error);
    }
  };

  // useEffect(() => {
  //   console.log("password", password);
  // }, [password]);
  // useEffect(() => {
  //   console.log("phone", phone);
  // }, [phone]);

  return (
    <div className="w-screen h-screen bg-gray-100 flex items-center justify-center">
      {/* form container */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-16 bg-white rounded-xl flex-col justify-center items-center gap-8 inline-flex"
      >
        <div className="h-40 w-40 p-1 overflow-hidden">
          <img src={myImage} alt="ChipChip.png" />
        </div>

        {/* phone number input */}
        {!switchPassword ? (
          <div className="w-[428px]">
            <Controller
              control={control}
              name="phone"
              rules={{ required: "required" }}
              render={({ field: { ref, ...field } }) => (
                <CustomPhoneInput
                  {...field}
                  error={errors.phone ? errors.phone?.message : undefined}
                  onChange={(phone) => {
                    field.onChange(phone);
                    setPhoneNumber(phone);
                  }}
                  inputProps={{
                    ref,
                    required: true,
                    autoFocus: true,
                  }}
                />
              )}
            />
          </div>
        ) : (
          <>
            {/* password */}
            <div className="w-[428px]">
              <TextInput
                {...(register("password"), { required: true })}
                error={errors.password ? errors.password?.message : undefined}
                placeholder="password"
                type="password"
                onChange={(el) => setPassword(el.target.value)}
              />
            </div>
          </>
        )}

        {/* <div className="w-[428px]">
          <TextInput
            {...(register("password"), { required: true })}
            error={errors.password ? errors.password?.message : undefined}
            placeholder="password"
            type="password"
            onChange={(el) => setPassword(el.target.value)}
          />
        </div> */}
        <div className="flex flex-col w-full items-center">
          {error && (
            <div className="p-2">
              <span className="text-red-500">{error}</span>
            </div>
          )}
          {/* sign in btn */}
          <div className="w-full">
            <button
              disabled={loading}
              className={` bg-primary p-3 disabled:bg-opacity-60 hover:bg-opacity-60 rounded-lg w-full text-white `}
            >
              {!loading ? (
                <span className="">Sign In</span>
              ) : (
                <>
                  <span className="loading loading-spinner"></span>
                  loading
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
