import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./Table.css";
import { Link } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import PaymentPaginations from "../../Paginations/PaymentPaginations";
import SkeletonLoading from "../../SkeletonLoading/SkeletonLoading";
import Logo from "../../../assets/icons/ChipChip.png";
import Popup from "../../Popup/Popup";
import { formatCreatedAt } from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../context/UserContext";

const Table = () => {
  const [data, setData] = useState({});
  const [pageSize, setPageSize] = useState(20);
  // const [user, setUser] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(0);
  const [amount, setAmount] = useState(0);
  const [filtering, setFiltering] = useState(false);

  const [paymentDetails, setPaymentDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [paymentId, setPaymentId] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(0);
  const navigate = useNavigate();

  const { user, updateUser } = useContext(UserContext);

  const handleOpenPopup = async (paymentId) => {
    setShowPopup(true);
    setPaymentId(paymentId);
    try {
      setLoadingDetails(true);
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL ||
          "https://stagingpayment.chipchip.social/v1"
        }/system/payments/${paymentId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      // console.log("response", response.data);
      setPaymentDetails(response.data.data);

      // const responseUser = await axios.get(
      //   `${
      //     process.env.REACT_APP_API_URL ||
      //     "https://stagingapi.chipchip.social/v1"
      //   }/system/users/${response.data.data.customer_id}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${user.token}`,
      //     },
      //   }
      // );
      // console.log("responseUser", responseUser.data);

      setLoadingDetails(false);
    } catch (error) {
      // console.log(error);
      setLoadingDetails(false);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    setPaymentDetails({});
  };

  const fetchData = async (newPage, newPageSize, filtering, amount) => {
    try {
      setLoading(true);
      // console.log("hello fetching");
      const response = await axios.get(
        !(filtering && amount > 0)
          ? `${
              process.env.REACT_APP_API_URL ||
              "https://stagingpayment.chipchip.social/v1"
            }/system/payments?page=${newPage}&per_page=${newPageSize}`
          : `${
              process.env.REACT_APP_API_URL ||
              "https://stagingpayment.chipchip.social/v1"
            }/system/payments?page=${newPage}&per_page=${newPageSize}&filter=[{"column_field":"amount", "operator_value":"=", "value":"${amount}"}]`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      // console.log("response", response.data);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("user", user);
    if (user && user.access_token) {
      fetchData(currentPage + 1, pageSize, filtering, amount);
    }
  }, [user, currentPage, pageSize, filtering]);

  const handleFilter = () => {
    setFiltering(true);
    fetchData(currentPage + 1, pageSize, filtering, amount);
  };

  const logoutUser = async () => {
    try {
      // Call the logout endpoint
      await axios.post(
        `${
          process.env.REACT_APP_API_URL ||
          "https://stagingpayment.chipchip.social/v1"
        }/system/users/logout`,
        {}, // Adjust the payload if required
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`, // Ensure `user` exists
          },
        }
      );
    } catch (error) {
      console.error("Logout API failed:", error);
    } finally {
      // Clear user data and redirect
      localStorage.removeItem("user-info");
      updateUser(null);
      navigate("/login");
    }
  };

  return (
    <div className="h-full flex items-center flex-col justify-between gap-3 w-full p-3 bg-gray-100">
      <div className="bg-white rounded-2xl h-16 w-full p-3 flex items-center justify-center">
        <div className="w-full flex items-center justify-between gap-3">
          {/* search side */}
          <div className="flex justify-between items-center flex-row-reverse">
            <div className="flex items-center gap-1">
              <div className="h-10 w-10 p-1 overflow-hidden">
                <img src={Logo} className="h-full w-full" alt="" />
              </div>
              <span className="font-semibold text-[1.1rem]">
                ChipChip Orders Payments
              </span>
            </div>
          </div>
          {/* icons div */}

          <div className=" flex items-center justify-around gap-3">
            {/* <div className="flex items-center gap-2">
              <span>Amount</span>
              <div
                className="flex items-center gap-1 justify-center border border-gray-600 rounded-md p-1 cursor-pointer
                "
              >
                <input
                  type="number"
                  onChange={(e) => setAmount(Number(e.target.value))}
                  className="outline-none border-none focus:outline-none focus:border-none bg-none h-full w-full"
                />
              </div>
            </div>
            <div
              className=" rounded-md bg-green p-1.5 px-2.5 text-center text-white font-light flex items-center justify-center cursor-pointer "
              onClick={() => handleFilter()}
            >
              Filter
            </div> */}
            <div
              className=" rounded-md bg-primary p-1.5 px-2.5 text-center text-white font-light flex items-center justify-center cursor-pointer "
              onClick={() => logoutUser()}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
      <div className="h-full max-h-full  rounded-md border p-2 w-full">
        <div className="w-full h-full flex flex-col gap-2 items-center justify-between">
          <div className="h-[70vh] max-h-[75vh] overflow-auto w-full bg-white">
            <table className="table-auto w-full flex-[1] h-full overflow-auto text-center">
              <thead>
                <tr>
                  <th className="p-2 text-[10px] text-center">No</th>
                  <th className="p-2 text-[10px] text-center">Service Name</th>
                  <th className="p-2 text-[10px] text-center">Amount</th>
                  <th className="p-2 text-[10px] text-center">Phone</th>
                  <th className="p-2 text-[10px] text-center">Description</th>
                  <th className="p-2 text-[10px] text-center">Payment Type</th>
                  <th className="p-2 text-[10px] text-center">Status</th>
                  <th className="p-2 text-[10px] text-center">Created At</th>
                  <th className={`"p-1 text-[10px] text-center w-20" `}>
                    Actions
                  </th>
                </tr>
              </thead>
              {loading ? (
                <SkeletonLoading />
              ) : (
                <tbody>
                  {data?.data?.map((payment, index) => {
                    return (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-gray-100" : ""}
                      >
                        <td className=" p-1.5 text-[11px]">
                          {index + 1 + (currentPage > 0 ? currentPage * 20 : 0)}
                        </td>
                        <td className=" p-1.5 text-[11px]">
                          {payment?.service_name}
                        </td>
                        <td className=" p-1.5 text-[11px]">
                          <div className="flex gap-0.5 items-center">
                            <span>{payment?.amount}</span>
                            <span className="text-[.5rem]">ETB</span>
                          </div>
                        </td>
                        <td className=" p-1.5 text-[11px]">
                          +{payment?.customer_phone}
                        </td>
                        <td className=" p-1.5 text-[11px] ">
                          {payment?.description}
                        </td>
                        <td className=" p-1.5 text-[11px] ">
                          {payment?.payment_type}
                        </td>
                        <td className=" p-1.5 text-[11px] ">
                          {payment?.status}
                        </td>
                        <td className=" p-1.5 text-[11px] ">
                          {formatCreatedAt(payment?.created_at)}
                        </td>
                        <td className=" p-1.5 text-[11px] ">
                          <div className="w-full flex justify-center">
                            <button
                              className="bg-green text-white p-1 text-[10px] px-2 rounded flex items-center justify-center gap-1"
                              onClick={() => handleOpenPopup(payment.id)}
                            >
                              <BsEye className="text-white" />
                              View
                            </button>
                          </div>
                          {showPopup && paymentId === payment.id && (
                            <Popup show={showPopup} onClose={handleClosePopup}>
                              <div className="flex flex-col justify-center gap-2">
                                {loadingDetails && <span>Loading...</span>}
                                {paymentDetails && (
                                  <>
                                    <span className="text-cebter font-semibold text-[.9rem]">
                                      Payment Details
                                    </span>
                                    <div className="flex flex-col gap-1.5">
                                      <div className="w-full flex items-center gap-1 justify-between p-1 border-b border-b-gray-300">
                                        <span className="text-[.9rem]">
                                          Payment Type
                                        </span>{" "}
                                        <span className="text-[.9rem]">
                                          {paymentDetails.payment_type}
                                        </span>
                                      </div>
                                      <div className="w-full flex items-center gap-1 justify-between p-1 border-b border-b-gray-300">
                                        <span className="text-[.9rem]">
                                          Amount
                                        </span>{" "}
                                        <span className="text-[.9rem]">
                                          {paymentDetails.amount}
                                        </span>
                                      </div>
                                      <div className="w-full flex items-center gap-1 justify-between p-1 border-b border-b-gray-300">
                                        <span className="text-[.9rem]">
                                          Status
                                        </span>{" "}
                                        <span className="text-[.9rem]">
                                          {paymentDetails.status}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </Popup>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          <div className="w-full h-[10%] bg-white p-1">
            <PaymentPaginations
              data={data}
              fetchData={fetchData}
              loading={loading}
              currentPage={currentPage}
              setPageSize={setPageSize}
              setCurrentPage={setCurrentPage}
              filtering={filtering}
              amount={amount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;
