import { useEffect, useContext } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import UserContext from "../context/UserContext";

const useTokenRefresh = () => {
  const { user, updateUser } = useContext(UserContext);

  useEffect(() => {
    // Define the refresh token function
    const refreshToken = async () => {
      if (user?.refresh_token === ""){
          return
      }

      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_API_URL ||
            "https://stagingpayment.chipchip.social/api/v1"
          }/system/users/tokens/refresh`,
          {}, // No body required here, but ensure the request shape matches the API
          {
            headers: {
              Authorization: `Bearer ${user?.refresh_token}`, // Ensure `user` exists
            },
          }
        );

        // Update the user data with the new token
        if (response?.data?.access_token) {
          updateUser({ ...user, access_token: response.data.access_token });
        }
      } catch (error) {
        console.error("Failed to refresh token:", error);
        return <Navigate to="/login" replace />;
      }
    };

    // Run the token refresh every 5 minutes
    const intervalId = setInterval(() => {
      refreshToken();
    }, 300000); // 300,000 ms = 5 minutes

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [user, updateUser]);

  return null; // Hook doesn't render anything
};

export default useTokenRefresh;
