import { useEffect, useState } from "react";

const useIdleTimeout = (timeout = 4 * 60 * 1000) => {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    let idleTimer;

    const resetTimer = () => {
      if (idleTimer) clearTimeout(idleTimer);
      idleTimer = setTimeout(() => setIsIdle(true), timeout);
    };

    // Event listeners to detect user activity
    const events = ["mousemove", "keydown", "scroll", "touchstart"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Initialize the timer
    resetTimer();

    // Cleanup event listeners and timer
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (idleTimer) clearTimeout(idleTimer);
    };
  }, [timeout]);

  return isIdle;
};

export default useIdleTimeout;